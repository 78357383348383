import React from 'react';
import { Button } from 'react-bootstrap';

import './CustomIconButton.scss';

const CustomIconButton = (props) => {
    const { title, disabled, type, btnicon, className, loading, onClick, style, active } = props;
    return (
        <Button
            className={'iconBtn ' + className}
            type={type}
            // title={title}
            onKeyDown={props.onKeyDown}
            disabled={disabled || loading}
            onClick={onClick}
            style={style}
        >
            <>
                <i className={'icon icon-' + btnicon}></i>
                {active && <span className='dotActive red'></span>}
                <span>{title}</span>
            </>
        </Button>
    );
};

CustomIconButton.defaultProps = {
    className: ' '
};

export default CustomIconButton;
