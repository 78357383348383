/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AdminLayoutRoute } from './../../routes';
import Header from '../Admin/Header/Header';
// const PageNotFound = lazy(() =>
//     import(
//     /* webpackChunkName: "login" */ /* webpackPreload: true */ '../../pages/PageNotFound/PageNotFound'
//     )
// );

const AdminLayout = (props) => {
    const location = useLocation();
    const [isLogin, setIsLogin] = useState(true);
    const { pathname } = location;

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('aviagen_access_token') ? true : false;
        setIsLogin(isLoggedIn);
    }, []);

    const authenticateRoute = () => {
        const routeIndex = AdminLayoutRoute.findIndex(route => route.path === pathname.replace('/admin', ''));
        if (pathname.replace('/admin', '') !== '/' && routeIndex !== -1) {
            const Component = AdminLayoutRoute[routeIndex].component;
            return isLogin ? Component : <Navigate replace to='/' />;
        } else {
            // return <PageNotFound />;
            return <Navigate replace to='/' />;
        }
    };

    return (
        <>
            <Header {...props} />
            <Routes>
                {AdminLayoutRoute.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            exact={route.exact}
                            path={route.path}
                            element={authenticateRoute(props)}
                        />
                    );
                })}
            </Routes>
        </>
    );
};
// class AdminLayout extends React.Component {
//     render () {
//         return (
//             <>
//                 <Header {...this.props} />
//                 <Routes>
//                     {AdminLayoutRoute.map((route, index) => {
//                         return (
//                             <Route
//                                 key={index}
//                                 exact={route.exact}
//                                 path={route.path}
//                                 element={route.component}
//                             />
//                         );
//                     })}
//                 </Routes>
//             </>
//         );
//     }
// }

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
