import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    region: process.env.REACT_APP_REGION
});

let S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;

if (process.env.REACT_APP_ENVIRONMENT === 'main') {
    S3_BUCKET = `${process.env.REACT_APP_BUCKET_NAME}/Aviagen_Documents`;
}

export function isCheckValueAndSetParams (params, value) {
    if (typeof (value) === 'undefined' || value === null || value === '') {
        return '';
    }
    return params + value;
}

export const downloadFile = (link) => {
    fetch(link)
        .then(response => {
            const filename = response.url.split('/');
            response.blob().then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename[filename.length - 1];
                a.click();
            });
        });
};

export function capitalizeFirstLetter (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const uploadFile = async (File, userData) => {
    if (File.length > 0) {
        const file = File[0];
        // const arr = file.name.split('.');
        // const mimetype = file.type.split('/');
        // if (file.type === 'image/svg+xml') {
        //     updatedParams.Metadata = [{
        //         'Content-Type': 'image/svg+xml'
        //     }];
        // }
        const updatedParams = {
            Bucket: S3_BUCKET,
            ContentType: file.type,
            Key: file.name,
            Body: file,
            ACL: 'public-read'
        };
        console.log('updatedParams', updatedParams);

        const upload = new AWS.S3.ManagedUpload({
            params: updatedParams
        });
        console.log('upload----> ', JSON.stringify(upload));
        upload.on('httpUploadProgress', (progress) => {
            // const progressPercentage = Math.round(progress.loaded / progress.total * 100);
            // console.log(progressPercentage);
        });
        const promise = upload.promise();
        console.log('proise-->', promise);

        return promise;
    } else {
        // setLoader(false);
        // props.showToast({ message: 'Only PDF,JPG,JPEG,DOC and DOCS files are allowed.', type: 'error' });
    }
    const reader = new FileReader();
    reader.onload = function (event) {
        // setProductImg1(event.target.result);
    };
    reader.readAsDataURL(File[0]);

    // const formdata = new FormData();
    // formdata.append('file', file);
    // formdata.append('cloud_name', process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    // formdata.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);
    // const res = await fetch(
    //     'https://api.cloudinary.com/v1_1/zudu/upload',
    //     {
    //         method: 'post',
    //         mode: 'cors',
    //         body: formdata
    //     }
    // );

    // const json = await res.json();
    // const imageURL = json.secure_url.split('/');
    // return imageURL[imageURL.length - 1];
};

export const reduceDescriptionForTitle = (str) => {
    if (str.length > 250) {
        return `${str.replace(/\n/g, ' ').substring(0, 250)}`;
    } else {
        return str;
    }
};
export const reduceTitle = (str) => {
    if (str.length > 250) {
        return `${str.replace(/\n/g, ' ').substring(0, 250)}`;
    } else {
        return str;
    }
};
