
import loaderReducer from './loaderReducer';
import authReducer from './authReducer';
import { combineReducers } from 'redux';
import toastReducer from './toastReducer';
import mainLoaderReducer from './mainLoaderReducer';
import { createMultilanguageReducer } from 'redux-multilanguage';

const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: 'en' }),
    loading: loaderReducer,
    mainLoaderReducer,
    toastReducer,
    authReducer
});

export default rootReducer;
