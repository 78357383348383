/* eslint-disable no-unneeded-ternary */
import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { UserLayoutRoute } from './../../routes';
import Header from '../User/Header/Header';
const PageNotFound = lazy(() =>
    import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../../pages/PageNotFound/PageNotFound'
    )
);

const UserLayout = (props) => {
    const location = useLocation();
    const [isLogin, setIsLogin] = useState(true);
    const { pathname } = location;

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('aviagen_access_token') ? true : false;
        setIsLogin(isLoggedIn);
    }, []);

    const authenticateRoute = () => {
        const routeIndex = UserLayoutRoute.findIndex(route => route.path === pathname.replace('/user', ''));
        if (pathname.replace('/user', '') !== '/' && routeIndex !== -1) {
            const Component = UserLayoutRoute[routeIndex].component;
            return isLogin ? Component : <Navigate replace to='/' />;
        } else {
            return <PageNotFound />;
        }
    };

    return (
        <>
            <Header {...props} />
            <Routes>
                {UserLayoutRoute.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            exact={route.exact}
                            path={route.path}
                            element={authenticateRoute(props)}
                        />
                    );
                })}
            </Routes>
        </>
    );
};
// const UserLayout = (props) => {
//     return (
//         <div className="main">
//             <Header {...props} />
//             <Routes>
//                 {UserLayoutRoute.map((route, index) => {
//                     return <Route
//                         key={index}
//                         exact={route.exact}
//                         path={route.path}
//                         element={route.component}
//                     />;
//                 })}
//             </Routes>
//         </div>
//     );
// };

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);
