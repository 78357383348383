import React from 'react';

import Loader from '../../../Assets/Images/LoaderAvaigen.gif';
import Blinkimage from '../../../Assets/Images/BlinkImage.svg';

import './Loading.scss';
const Loading = ({
    show
}) => {
    return (
        show
            ? <div className='full'>
                <div className='innerDiv'>
                    <img src={Loader} alt='' />
                    <img className='blinkImage' src={Blinkimage} alt='' />
                </div>
            </div>
            : null);
};

Loading.defaultProps = {};

export default Loading;
