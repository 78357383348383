import React, { useState } from 'react';
import { Fade, Modal } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';

import './ConfirmModal.scss';

const ConfirmModal = (props) => {
    const { iconName, title, subTitle, leftBtnTitle, rightBtnTitle, leftBtnClass, rightBtnClass, onPressLeft, onPressRight } = props;
    const [btnVisible] = useState(props.btnVisible);

    return (
        <Modal
            className={'confirmModal ' + props.className}
            show={props.showModal}
            animation={Fade}
            onHide={() => { onPressLeft(); }}
            centered
        >
            {iconName && <i className={'icon-' + iconName} />}
            {title && <h3 className="title">{title}</h3>}
            {subTitle && <p className="subTitle">{subTitle}</p>}

            {btnVisible && <div className='modal-footer'>
                <CustomButton type="submit" className={leftBtnClass} title={leftBtnTitle} onClick={() => { onPressLeft(true); }} />
                <CustomButton type="button" className={`cancelBtn${rightBtnClass}`} title={rightBtnTitle} onClick={() => { onPressRight(false); }} />
            </div>}
        </Modal>
    );
};

ConfirmModal.defaultProps = {
    className: 'confirmModal',
    btnVisible: true
};

export default (ConfirmModal);
