import React, { useState } from 'react';
import { PubNubProvider, PubNubConsumer } from 'pubnub-react';
import Notification from 'react-web-notification';

import App from './App';
import { pubnub, addListener } from './services/pubnub';
import { sendNotification } from './redux/actions/notificationAction';
import { useDispatch, connect } from 'react-redux';
import AxiosInstance from './services/axios';

function PubnubProvider (props) {
    const [notification, setNotification] = useState({
        title: '',
        options: {}
    });
    const dispatch = useDispatch();

    const notificationOptions = (description) => {
        return {
            tag: Date.now(),
            body: description,
            lang: 'en',
            dir: 'ltr'
        };
    };

    const notifyNotification = () => {
        AxiosInstance.get('/auth/getNotification').then((res) => {
            console.log('Response', res);
            dispatch({
                type: 'NOTIFICATION_INFO',
                payload: res.payload
            });
        }).catch(err => { console.log('Error', err); });
    };

    const setNotificationEnvironment = (client) => {
        addListener(client, (message) => {
            setNotification({
                title: message.title,
                options: notificationOptions(message.description)
            });
            // props.getNotificationList()
        });
    };

    return (
        <div>
            <Notification
                timeout={5000}
                title={notification.title}
                options={notification.options}
                onShow={() => {
                    console.log('namste');
                    notifyNotification();
                }}
            />
            <PubNubProvider client={pubnub}>
                <PubNubConsumer>
                    {(client) =>
                        notification.title === '' && setNotificationEnvironment(client)
                    }
                </PubNubConsumer>

                <App />
            </PubNubProvider>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {
    sendNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(PubnubProvider);
