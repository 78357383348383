// import WebService from '../../util/webService';
// import constant from '../../util/constant';
export const SET_LOADER = 'SET_LOADER';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const setLoader = (isValue) => {
    return dispatch => {
        dispatch({
            type: SET_LOADER,
            payload: isValue
        });
    };
};

export const showLoader = () => {
    return dispatch => {
        dispatch({
            type: SHOW_LOADER
        });
    };
};

export const hideLoader = () => {
    return dispatch => {
        dispatch({
            type: HIDE_LOADER
        });
    };
};
