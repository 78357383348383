/* eslint-disable no-useless-escape */
const Constant = {
    REGEX: {
        EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        SPECIALCHARACTERS: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
        UPPERCASE: /.*[A-Z]|[A-Z]/,
        LOWERCASE: /.*[a-z]|[a-z]/,
        NUMBER: /[0-9]/,
        NAME: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
        NECNUMBER: /^[0-9]{10}TMP$/,
        ALPHANUMERIC: /^[ a-zA-Z0-9\.\-\,]+$/i,
        ALPHABETIC: /^[A-Za-z ]+$/,
        ACCENTEDALPHABETIC: /^[A-zÀ-ú- ]+$/,
        VERSIONNUMERIC: /^(?=.*[0-9])[A-Za-z0-9\.]+$/,
        VERSIONFINAL: /^[A-Za-z0-9\.]+$/
    }
};
export default Constant;
