import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'react-bootstrap';
import { multilanguage } from 'redux-multilanguage';
import { hideToast } from '../../../redux/actions/toastAction';
import './ToastComponent.scss';

const ToastComponent = (props) => {
    const { toast: { type, message, show } } = props;
    const [showToast, setShowToast] = useState(true);

    useEffect(() => {
        setShowToast(show);
    }, [show]);

    let toastClass;
    if (type === 'Success') {
        toastClass = 'success-txt';
    } else if (type === 'Error') {
        toastClass = 'error-txt';
    } else if (type === 'Warning') {
        toastClass = 'warning-txt';
    } else {
        toastClass = '';
    }
    return (
        message !== '' && <Toast onClose={() => props.hideToast()} className={toastClass} show={showToast} delay={3000} autohide>
            <div className="tostcenter">
                <div className='leftIcon'>
                    {type === 'Success' &&
                        <i className='icon icon-Fill_Check' />
                    }
                    {type === 'Error' &&
                        <i className='icon icon-Fill_Close' />
                    }
                    {type === 'Warning' &&
                        <i className='icon icon-Warning-Toast' />
                    }
                </div>
                <div className='rightContent'>
                    <p className='type'>{`${type}!`}</p>
                    <p className='tostMassage'>{message}</p>
                </div>
            </div>
        </Toast>
    );
};

const mapStateToProps = (state) => {
    return {
        toast: state.toastReducer.toast
    };
};
const mapDispatchToProps = {
    hideToast
};
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(ToastComponent));
