import React from 'react';
import { Fade, Modal } from 'react-bootstrap';
import './ModalPopup.scss';

const ModalPopup = (props) => {
    return (
        <Modal
            className={'modal ' + props.className}
            show={props.showModal}
            animation={Fade}
            onHide={() => props.onHide()}
            centered
        >
            {props.closeIcon &&
              <i className="icon-cross" onClick={() => { props.onHide(); }}></i>
            }
            {props?.children}
        </Modal>
    );
};

ModalPopup.defaultProps = {
    className: 'modal'
};

export default (ModalPopup);
