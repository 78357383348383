import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from './routes';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import { connect, useSelector } from 'react-redux';
import { hideToast } from './redux/actions/toastAction';
import ToastComponent from './Components/UI/ToastComponent/ToastComponent';
import Loader from './Components/UI/Loading/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import { pubnub } from './services/pubnub';
import './App.scss';

function App (props) {
    const state = useSelector(state => state);
    useEffect(() => {
        props.dispatch(
            loadLanguages({
                languages: {
                    en: require('./translations/english.json')
                }
            })
        );
    }, []);

    useEffect(() => {
        const user = props.userInfo;
        // console.log('user', user);
        if (user) {
            const channels = [user?._id];
            pubnub.subscribe({ channels });
        }
        return () => {
            const channels = [user?._id];
            pubnub.unsubscribe({
                channels
            });
        };
    }, []);

    window.onbeforeunload = (e) => {
        props.hideToast();
    };

    return (
        <div className="App">
            <Suspense fallback={<h1></h1>}>
                <ToastComponent/>
                <Loader show={state?.mainLoaderReducer?.showLoader} />
                <BrowserRouter>
                    <Routes>
                        {routes.map((route, index) => {
                            return (
                                <Route
                                    key={index}
                                    exact={route.exact}
                                    path={route.path}
                                    element={route.component}
                                />
                            );
                        })}
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.authReducer.userinfo
    };
};

const mapDispatchToProps = {
    hideToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(App));
