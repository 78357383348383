const ACCESS_TOKEN_KEY = 'aviagen_access_token';
const REFRESH_TOKEN_KEY = 'aviagen_refresh_token';

export const getToken = () => {
    return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const saveToken = (access, refresh) => {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, access);
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.localStorage.removeItem('aviagen_userRole');
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getToken, saveToken, destroyToken, getRefreshToken };
