const USER_INFO = 'USER_INFO';
const NOTIFICATION_INFO = 'NOTIFICATION_INFO';
const NOTIFICATION_READ = 'NOTIFICATION_READ';
const initState = {
    userinfo: {},
    notification: [],
    isNotificationUnread: false
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
    case USER_INFO:
        return {
            ...state,
            userinfo: action.payload
        };
    case NOTIFICATION_INFO:
        return {
            ...state,
            notification: action.payload
        };
    case NOTIFICATION_READ:
        return {
            ...state,
            isNotificationUnread: action.payload
        };
    default:
    }
    return state;
};

export default authReducer;
