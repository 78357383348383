import axios from 'axios';
import { get } from 'lodash';
import jwtService from './../services/jwt.service';

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});
let isRefreshing = false;
let failedQueue = [];

// Process all failed request
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) { prom.reject(error); } else { prom.resolve(token); }
    });
    failedQueue = [];
};

AxiosInstance.interceptors.request.use(config => {
    if (jwtService.getToken()) { config.headers.Authorization = jwtService.getToken(); }
    return config;
});

AxiosInstance.interceptors.response.use(
    response => response.data,
    error => {
        const originalRequest = error.config;
        const responseStatus = get(error, 'response.status', '');

        // If refresh token fails
        if (responseStatus === 401 && error.config.url.indexOf('aviagen_refresh_token') !== -1) {
            processQueue(error, null);
            isRefreshing = false;
            return Promise.reject(error);
        }

        if (responseStatus === 401 && error.config.url.indexOf('login') !== -1) {
            return Promise.reject(error);
        }

        // Check if original request
        if (responseStatus === 401 && !originalRequest._retry) {
            // Push all the failed request due to expired token in queue
            if (isRefreshing) {
                return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
                    .then(token => {
                        originalRequest.headers.Authorization = 'Token ' + token;
                        return AxiosInstance(originalRequest);
                    })
                    .catch(err => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            // Try to refresh token
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-tokens`, { refreshToken: jwtService.getRefreshToken() })

                    /*
                        On success save token, set headers and start processing
                        previously failed requests with new token
                    */
                    .then(response => {
                        jwtService.saveToken(response.data.payload.tokens[0].access.token, response.data.payload.tokens[0].refresh.token);
                        axios.defaults.headers.common.Authorization = response.data.payload.tokens[0].access.token;
                        originalRequest.headers.Authorization = response.data.payload.tokens[0].access.token;
                        processQueue(null, response.data.payload.tokens[0].access.token);
                        resolve(axios(originalRequest));
                    })
                    /*
                        On error proccess old failed request with token value null
                        and redirect to respective authentication page
                    */
                    .catch(err => {
                        jwtService.destroyToken();
                        processQueue(err, null);
                        reject(err);
                        window.location.href = '/';
                    })
                    /*
                        Finally set isRefreshing token to false in either success or failure
                    */
                    // eslint-disable-next-line no-return-assign
                    .finally(() => isRefreshing = false);
            });
        }

        return Promise.reject(error);
    }
);

/**
 * Set the default HTTP request headers
 */
// const setHeader = () => {
//     AxiosInstance.defaults.headers.common.Authorization = `Token ${JwtService.getToken()}`;
// };

export default AxiosInstance;
