import PubNub from 'pubnub';
import StorageService from '../services/localstorage.service';
const uuid = PubNub.generateUUID();

export const pubnub = new PubNub({
    publishKey: 'pub-c-4defd26b-197f-418e-8c60-5483d6e36cd5',
    subscribeKey: 'sub-c-d1b934e8-46ad-4700-8021-31e56ad5484b',
    uuid: uuid
});

export const addListener = (client, callback) => {
    client &&
    client.addListener({
        message: (messageEvent) => {
            callback && callback(messageEvent.message);
        }
    });
};

export const subscribe = (client, user) => {
    if (user) {
        const channel = user.id;
        client && pubnub.subscribe({ channels: [channel] });
    }
};

export const unsubscribe = (client) => {
    const user = StorageService.getItem('user');
    if (user) {
        const channel = user.id;
        client && pubnub.unsubscribe({ channels: [channel] });
    }
};
