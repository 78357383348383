import React, { lazy } from 'react';
import UserLayout from './layout/User/index';
import AdminLayout from './layout/Admin/index';
import TechnicalUserLayout from './layout/TechnicalTeam/index';

const Login = lazy(() => import(
    /* webpackChunkName: 'login' */ /* webpackPreload: true */ './pages/Auth/Login/Login'
));
const ChangePassword = lazy(() => import(
    /* webpackChunkName: 'login' */ /* webpackPreload: true */ './pages/Auth/ChangePassword/ChangePassword'
));
const ForgotPassword = lazy(() => import(
    /* webpackChunkName: 'ForgotPassword' */ /* webpackPreload: true */ './pages/Auth/ForgotPassword/ForgotPassword'
));
const ResetPassword = lazy(() => import(
    /* webpackChunkName: 'ResetPassword' */ /* webpackPreload: true */ './pages/Auth/ResetPassword/ResetPassword'
));
const SetPassword = lazy(() => import(
    /* webpackChunkName: 'SetPassword' */ /* webpackPreload: true */ './pages/Auth/SetPassword/SetPassword'
));
const DocumentManagement = lazy(() => import(
    /* webpackChunkName: 'DocumentManagement' */ /* webpackPreload: true */ './pages/Common/DocumentManagement/DocumentManagement'
));
const ArchivesRequests = lazy(() => import(
    /* webpackChunkName: 'ArchivesRequests' */ /* webpackPreload: true */ './pages/Common/ArchivesRequests/ArchivesRequests'
));
const EditDocument = lazy(() => import(
    /* webpackChunkName: 'EditDocument' */ /* webpackPreload: true */ './pages/Common/DocumentManagement/EditDocument'
));
// const ViewEditDocument = lazy(() => import(
//     /* webpackChunkName: 'EditDocument' */ /* webpackPreload: true */ './pages/Common/DocumentManagement/EditDocument'
// ));
const AddDocument = lazy(() => import(
    /* webpackChunkName: 'AddDocument' */ /* webpackPreload: true */ './pages/Common/AddDocument/AddDocument'
));
const ViewDocument = lazy(() => import(
    /* webpackChunkName: 'ViewDocument' */ /* webpackPreload: true */ './pages/Common/ViewDocument/ViewDocument'
));
// const UserManagement = lazy(() => import(
//     /* webpackChunkName: 'UserManagement' */ /* webpackPreload: true */ './pages/Admin/UserManagement/UserManagement'
// ));
const UserManagement = lazy(() => import(
    /* webpackChunkName: 'UserManagement' */ /* webpackPreload: true */ './pages/Common/UserManagement/UserManagement'
));
const UserDocuments = lazy(() => import(
    /* webpackChunkName: 'UserDocuments' */ /* webpackPreload: true */ './pages/User/Documents/Documents'
));
const ViewedDocument = lazy(() => import(
    /* webpackChunkName: 'ViewedDocument' */ /* webpackPreload: true */ './pages/Common/UserManagement/ViewedDocuments'
));
const UserViewDocument = lazy(() => import(
    /* webpackChunkName: 'UserViewDocument' */ /* webpackPreload: true */ './pages/User/UserCommon/ViewDocument'
));
const UserArchives = lazy(() => import(
    /* webpackChunkName: 'UserArchives' */ /* webpackPreload: true */ './pages/User/Archives/Archives'
));
const UserFavourites = lazy(() => import(
    /* webpackChunkName: 'UserFavourites' */ /* webpackPreload: true */ './pages/User/Favourites/Favourites'
));
const PageNotFound = lazy(() => import(
    /* webpackChunkName: 'PageNotFound' */ /* webpackPreload: true */ './pages/PageNotFound/PageNotFound'
));

const routes = [
    // Auth
    {
        path: '/',
        component: <Login/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/login',
        component: <Login/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/forgotpassword',
        component: <ForgotPassword/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/resetpassword',
        component: <ResetPassword/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/setpassword',
        component: <SetPassword/>,
        authenticate: false,
        exact: true
    },
    // {
    //     path: '/changepassword',
    //     component: <ChangePassword/>,
    //     authenticate: false,
    //     exact: true
    // },

    // Admin User routes with header
    {
        path: '/admin/*',
        component: <AdminLayout />,
        authenticate: false,
        exact: true
    },
    // Technical routes without header
    {
        path: 'technicalteam/*',
        component: <TechnicalUserLayout/>,
        authenticate: false,
        exact: true
    },

    // User routes with header
    {
        path: '/*',
        component: <PageNotFound/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/user/*',
        component: <UserLayout/>,
        authenticate: false,
        exact: true
    }
];

export const AdminLayoutRoute = [
    // {
    //     path: '/',
    //     component: <DocumentManagement/>,
    //     authenticate: false,
    //     exact: true
    // },
    {
        path: '/documentmanagement',
        component: <DocumentManagement/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/documentmanagement/viewdocument',
        component: <ViewDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/documentmanagement/editdocument',
        component: <EditDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/archiverequests/editdocument',
        component: <EditDocument/>,
        authenticate: false,
        exact: true
    },
    // {
    //     path: '/viewdocument/editdocument',
    //     component: <ViewEditDocument/>,
    //     authenticate: false,
    //     exact: true
    // },
    {
        path: '/archiverequests',
        component: <ArchivesRequests/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/archiverequests/viewdocument',
        component: <ViewDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/documentmanagement/adddocument',
        component: <AddDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/usermanagement',
        component: <UserManagement/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/usermanagement/vieweddocuments',
        component: <ViewedDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/changepassword',
        component: <ChangePassword/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/*',
        component: <PageNotFound/>,
        authenticate: false,
        exact: true
    }
];
export const TechnicalUserLayoutRoute = [
    {
        path: '/documentmanagement',
        component: <DocumentManagement/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/archiverequests/editdocument',
        component: <EditDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/documentmanagement/editdocument',
        component: <EditDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/documentmanagement/adddocument',
        component: <AddDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/documentmanagement/viewdocument',
        component: <ViewDocument/>,
        authenticate: false,
        exact: true
    },
    // {
    //     path: '/viewdocument/editdocument',
    //     component: <ViewEditDocument/>,
    //     authenticate: false,
    //     exact: true
    // },
    {
        path: '/usermanagement',
        component: <UserManagement/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/usermanagement/vieweddocuments',
        component: <ViewedDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/archiverequests',
        component: <ArchivesRequests/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/archiverequests/viewdocument',
        component: <ViewDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/changepassword',
        component: <ChangePassword/>,
        authenticate: false,
        exact: true
    }
];
export const UserLayoutRoute = [
    {
        path: '/documents',
        component: <UserDocuments/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/documents/viewdocument',
        component: <UserViewDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/favourites/viewdocument',
        component: <UserViewDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/archives',
        component: <UserArchives/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/archives/viewdocument',
        component: <UserViewDocument/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/favourites',
        component: <UserFavourites/>,
        authenticate: false,
        exact: true
    },
    {
        path: '/changepassword',
        component: <ChangePassword/>,
        authenticate: false,
        exact: true
    }
];

export default routes;
