/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { multilanguage } from 'redux-multilanguage';
import { showToast } from '../../../redux/actions/toastAction';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Nav, Navbar, Dropdown } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import MetaTags from 'react-meta-tags';
import CustomIconButton from '../../../Components/UI/CustomIconButton/CustomIconButton';
import useWindowDimension from '../../../hooks/useWindowDimension';
import ModalPopup from '../../../Components/UI/ModalPopup/ModalPopup';
import HookForm from '../../../Components/HookForm/HookForm';
import FileUpload from '../../../Components/UI/FileUpload/FileUpload';
import TextField from '../../../Components/UI/TextField/TextField';
import CustomButton from '../../../Components/UI/CustomButton/CustomButton';
import ConfirmModal from '../../../Components/UI/ConfirmModal/ConfirmModal';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { get } from 'lodash';
import './Header.scss';
import AxiosInstance from '../../../services/axios';
import { uploadFile } from '../../../services/helper.service';
import jwtService from '../../../services/jwt.service';
import Constant from '../../../utils/constant';
import { showLoader, hideLoader } from '../../../redux/actions/loaderActions';
import moment from 'moment/moment';

const Header = (props) => {
    const { strings } = props;
    const { pathname } = useLocation();
    const dimensions = useWindowDimension();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [, setUserProfileForm] = useState();
    const [profImg, setProfImg] = useState(false);
    const [busy, setBusy] = useState(false);
    const history = useNavigate();
    const [userType, setUserType] = useState('');
    const userData = useSelector(state => state?.authReducer?.userinfo);
    const dispatch = useDispatch();
    const [notifiActive] = useState(true);
    const [notifyPage, setNotifyPage] = useState(1);
    const [checkAllNotification, showcheckAllNotification] = useState(false);
    const [showNotificationButton, setShowNotificationButton] = useState(false);

    const updateAdmin = async (e) => {
        props.showLoader();
        //  setTimeout(() => {
        // props.hideLoader();
        //  }, 1000);
        setBusy(true);
        const params = {
            ...e.Username.trim() && { sUserName: e.Username.trim() },
            // ...e.image && { sProfileImage: await uploadFile(e.image[0]) }
            ...e.image && {
                sProfileImage: await uploadFile(e.image, userData).then(
                    function (image) {
                        // setLoader(false);
                        // setShowImage(image.key);
                        console.log('image->', image.Location);
                        // const imageURL = json.secure_url.split('/');
                        // return imageURL[imageURL.length - 1];
                        console.log('image.keyyyy', image.key);
                        return image.Key;
                    },
                    function (err) {
                        console.log('err->', err);
                    }
                )
            }
        };
        console.log('params->', params);

        await AxiosInstance.patch('auth/updateUser', params).then((res) => {
            setShowProfile(false);
            props.showToast({
                message: strings.YOUR_PROFILE_WAS_UPDATED_SUCCESSFULLY,
                type: 'Success'
            });
            dispatch({
                type: 'USER_INFO',
                payload: res.payload
            });
            props.hideLoader();
        }).catch((err) => {
            props.showToast({
                message: get(err, 'response.data.message', strings.SOMETHING_WENT_WRONG),
                type: 'Error'
            });
        });
        setBusy(false);
    };
    const checkUserType = () => {
        if (pathname.toLowerCase().includes('admin')) {
            setUserType('ADMIN');
        } else if (pathname.toLowerCase().includes('technical')) {
            setUserType('TECHNICAL');
        }
    };

    // const onFormSubmit = (e) => {
    //     props.showToast({
    //         message: strings.YOUR_PROFILE_WAS_UPDATED_SUCCESSFULLY,
    //         type: 'Success'
    //     });
    //     setTimeout(() => {
    //         history('');
    //         setBusy(false);
    //     }, 3000);
    // };

    useEffect(() => {
        checkUserType();
        notifyNotification(1);
    }, []);

    const updatingUserType = (value) => {
        const params = {
            id: value._id,
            sFirstLogin: window.sessionStorage.getItem('aviagen_userRole') === 'technical' ? 'User' : 'Technical',
            bFirstLogin: false,
            sUserRole: window.sessionStorage.getItem('aviagen_userRole') === 'technical' ? 'User' : 'Technical'
        };
        AxiosInstance.post('/auth/updateOnLogin', params).then(res => {
            console.log('Response', res);
            window.localStorage.setItem('aviagen_userRole', window.sessionStorage.getItem('aviagen_userRole') === 'technical' ? 'user' : 'technical');
            history('/technicalteam/documentmanagement');
        });
    };

    const notifyNotification = (page) => {
        AxiosInstance.get(`/auth/getNotification?page=${page}`).then((res) => {
            // console.log('Response', res);
            // console.log('res', res?.payload?.map(e => e?.notifications?.map(e => e?.receiversList.filter(e => e?.rStatus === 'Unread' && e.receiverId === userData?._id))));
            const unreadArray = [];
            if (page === 1) {
                dispatch({
                    type: 'NOTIFICATION_INFO',
                    payload: res.payload
                });
                res?.payload.forEach(e => {
                    e?.notifications.length > 0 && e?.notifications.forEach(e => {
                        e?.receiversList.length > 0 && e?.receiversList.forEach(item => {
                            if (item?.rStatus === 'Unread' && item.receiverId === userData?._id) {
                                unreadArray.push(item);
                                showcheckAllNotification(true);
                            }
                        });
                    });
                });
                res.payload.length === 0
                    ? dispatch({
                        type: 'NOTIFICATION_READ',
                        payload: true
                    })
                    : unreadArray.length > 0
                        ? dispatch({
                            type: 'NOTIFICATION_READ',
                            payload: false
                        })
                        : dispatch({
                            type: 'NOTIFICATION_READ',
                            payload: true
                        });
            } else {
                const notificationList = [...props.notificationInfo, ...res.payload];
                notificationList.forEach(e => {
                    e?.notifications.length > 0 && e?.notifications.forEach(e => {
                        e?.receiversList.length > 0 && e?.receiversList.forEach(item => {
                            if (item?.rStatus === 'Unread' && item.receiverId === userData?._id) {
                                unreadArray.push(item);
                            } else if (item.receiverId === userData?._id && item.rStatus === 'read') {
                                showcheckAllNotification(true);
                            }
                        });
                    });
                });
                notificationList.length === 0
                    ? dispatch({
                        type: 'NOTIFICATION_READ',
                        payload: true
                    })
                    : unreadArray.length > 0
                        ? dispatch({
                            type: 'NOTIFICATION_READ',
                            payload: false
                        })
                        : dispatch({
                            type: 'NOTIFICATION_READ',
                            payload: true
                        });
                dispatch({
                    type: 'NOTIFICATION_INFO',
                    payload: notificationList
                });
            }
        }).catch(err => { console.log('Error', err); });
    };

    const gettingDay = (date) => {
        const a = moment(new Date());
        const b = moment(date.replace(/-/g, '/'));
        const c = a.diff(b, 'days');
        return c;
    };

    const updateNotification = async (value) => {
        const params = `${value?._id}`;
        await AxiosInstance.post(`auth/notificationUpdate/${params}`).then((res) => {
            console.log('res', res?.payload);
            if ((value?.type === 'accessRequest' || value?.type === 'documentUpdates') && userType === !'TECHNICAL' && userType === !'ADMIN') {
                history('./documents/viewdocument', {
                    state: {
                        value: value?.documentData?._id,
                        path: 'documents'
                    }
                });
            }
            if (value?.type === 'accessRequest' && (userType === 'TECHNICAL' || userType === 'ADMIN')) {
                history('./archiverequests');
            }
            if (value?.type === 'documentUpdates' && (userType === 'TECHNICAL' || userType === 'ADMIN')) {
                history('./documentmanagement/viewdocument', {
                    state: {
                        value: value?.documentData?._id,
                        path: 'documents'
                    }
                });
            }
            if (value?.type === 'updateUser' && (userType === 'TECHNICAL' || userType === 'ADMIN')) {
                history('./usermanagement');
            }
            setShowNotification(false);
            notifyNotification(1);
        }).catch((err) => console.log('err', err));
    };

    const markAllReadNotification = async () => {
        await AxiosInstance.post('auth/readAllNotifications').then(() => {
            setShowNotification(false);
            notifyNotification(1);
            setShowNotificationButton(true);
        }).catch();
    };

    const notificationPopUpData = (data) => {
        return data?.notifications.map((value, index) => {
            // setNotifyData(value);
            return (
                <div style={ { cursor: 'pointer' } } className={value?.receiversList.some(e => e.rStatus === 'read' && e.receiverId === userData?._id) ? 'notificationCard read' : 'notificationCard unRead'} key={index} onClick={() => {
                    updateNotification(value);
                }}>
                    <p className='text'>{value?.title}</p>
                    <div className='detail'>
                        <div className='left'>
                            <div className='documentType'>
                                {(value?.documentData?.sFile)
                                    ? <img
                                        src={value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'JPEG' || value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'JPG'
                                            ? process.env.REACT_APP_AWS_DOC_BASE_URL + value?.documentData?.sFile
                                            : value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'XLS'
                                                ? process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_xls.svg'
                                                : value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'XLSX'
                                                    ? process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_Xlsx.svg'
                                                    : value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'WAV'
                                                        ? process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_Xlsx.svg'
                                                        : value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'MP3'
                                                            ? process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_mp3.svg'
                                                            : value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'PNG'
                                                                ? process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_Png.svg'
                                                                : value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'PDF'
                                                                    ? process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_pdf.svg'
                                                                    : value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'MP4'
                                                                        ? process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_mp4.svg'
                                                                        : value?.documentData?.sFile?.substring(value?.documentData?.sFile?.lastIndexOf('.') + 1).toUpperCase() === 'CSV' &&
                                                                        process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_Csv.svg'
                                        }
                                        alt='file'
                                    />
                                    : <img src={`${process.env.REACT_APP_AWS_BASE_URL}Static-images/DP.svg`} alt='profile'/>
                                }
                            </div>
                            <div className='textDetail'>
                                <div className='ducumentName'>
                                    <p className='text'>{ value?.documentData?.sDocumentTitle || value?.usersData?.sUserName }</p>
                                    <span>{value?.documentData?.sVersion}</span>
                                </div>
                                <p className='subText'>{value?.documentData?.sAuthorName || value?.usersData?.sEmail }</p>
                            </div>
                        </div>
                        <div className='right'>
                            <p>{moment(value?.updatedAt).format('hh:mm A')}</p>
                        </div>
                    </div>
                </div>
            );
        });
    };

    const setOnPressConfirm = () => {
        dispatch({
            type: 'USER_INFO',
            payload: ''
        });
        jwtService.destroyToken();
        window.sessionStorage.clear();
        history('/login');
    };

    const onPhotoChange = (acceptedFiles) => {
        const maxfilesize = (1024 * 1024 * 10);
        const filesize = acceptedFiles[0].size;
        if (filesize > maxfilesize) {
            props.showToast({
                message: 'File Size is greater than 10 MB. Please upload File below 10 MB.',
                type: 'Error'
            });
        } else {
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                setProfImg(event.target.result);
            });
            reader.readAsDataURL(acceptedFiles[0]);
        }
    };

    const Profile = {
        Username: {
            name: 'Username',
            validate: {
                required: {
                    value: true,
                    message: strings.ENTER_YOUR_FULL_NAME
                },
                pattern: {
                    value: Constant.REGEX.ACCENTEDALPHABETIC,
                    message: strings.ALPHABETS_ONLY
                }
            }
        }
    };
    let activeMenu = '';
    if (pathname.indexOf('documentmanagement') !== -1) {
        activeMenu = 'documentmanagement';
    } else if (pathname.indexOf('archiverequests') !== -1) {
        activeMenu = 'archiverequests';
    } else if (pathname.indexOf('usermanagement') || (pathname.indexOf('/admin/vieweddocuments')) !== -1) {
        activeMenu = 'usermanagement';
    } else if (pathname.indexOf('notification') !== -1) {
        activeMenu = 'notification';
    }
    // const notificationData = [
    //     {
    //         id: '1',
    //         title: 'A user requested to access archived document version 2.3.5.',
    //         src: process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_pdf.svg',
    //         documentName: 'Brooding',
    //         version: '2.3.5',
    //         autherName: 'Gerry Smith',
    //         time: '2:41 PM'
    //     },
    //     {
    //         id: '2',
    //         title: 'A user requested to access archived document version 2.3.5.',
    //         src: process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_pdf.svg',
    //         documentName: 'Brooding',
    //         version: '2.3.5',
    //         autherName: 'Gerry Smith',
    //         time: '2:41 PM'
    //     },
    //     {
    //         id: '3',
    //         title: 'A user requested to access archived document version 2.3.5.',
    //         src: process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_pdf.svg',
    //         documentName: 'Brooding',
    //         version: '2.3.5',
    //         autherName: 'Gerry Smith',
    //         time: '2:41 PM'
    //     },
    //     {
    //         id: '4',
    //         title: 'A user requested to access archived document version 2.3.5.',
    //         src: process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_pdf.svg',
    //         documentName: 'Brooding',
    //         version: '2.3.5',
    //         autherName: 'Gerry Smith',
    //         time: '2:41 PM'
    //     },
    //     {
    //         id: '5',
    //         title: 'A user requested to access archived document version 2.3.5.',
    //         src: process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_pdf.svg',
    //         documentName: 'Brooding',
    //         version: '2.3.5',
    //         autherName: 'Gerry Smith',
    //         time: '2:41 PM'
    //     },
    //     {
    //         id: '6',
    //         title: 'A user requested to access archived document version 2.3.5.',
    //         src: process.env.REACT_APP_AWS_BASE_URL + 'Static-images/Document_Type_pdf.svg',
    //         documentName: 'Brooding',
    //         version: '2.3.5',
    //         autherName: 'Gerry Smith',
    //         time: '2:41 PM'
    //     }
    // ];
    return (
        <>
            <MetaTags>
                <title>Aviagen Super Admin | Document Management & Archives Portal</title>
                <meta name="description" content="View, manage, and download all versions of the paperwork and documents you need, including historical and archived data. " />
                <meta property="og:title" content="Aviagen Super Admin | Document Management & Archives Portal" />
                <meta property="og:description" content="View, manage, and download all versions of the paperwork and documents you need, including historical and archived data.  " />
                <meta property="og:image" content="https://aviagen-live.s3.eu-west-2.amazonaws.com/Aviagen/Static-images/OG_Aviagen.png" />
            </MetaTags>
            <div className="headerAdmin">
                <div className='header'>
                    <Navbar style={{ height: dimensions.height + 'px' }}>
                        <Navbar.Toggle aria-controls='header-nav' className={notifiActive ? 'header-nav notification' : 'header-nav'} />
                        <Navbar.Collapse id="header-nav">
                            <Nav activeKey={activeMenu}>
                                <Navbar.Brand className='navbar-logo'>
                                    <Link to='/admin/documentmanagement'>
                                        <img src={`${process.env.REACT_APP_AWS_BASE_URL}Static-images/logo.svg`} title="" alt="" className="logo" />
                                    </Link>
                                </Navbar.Brand>
                                <div className='navTabMain'>
                                    <div className='navTab'>
                                        <Nav.Link eventKey="documentmanagement" as={Link} to='/admin/documentmanagement'>
                                            <CustomIconButton
                                                btnicon='File_Document'
                                                title='Document Management'
                                                className='linkBtn'
                                            />
                                        </Nav.Link>
                                        <Nav.Link eventKey="archiverequests" as={Link} to="/admin/archiverequests">
                                            <CustomIconButton
                                                btnicon='Archive'
                                                title='Archived Requests'
                                                className='linkBtn'
                                            />
                                        </Nav.Link>
                                        <Nav.Link eventKey="usermanagement" as={Link} to="/admin/usermanagement">
                                            <CustomIconButton
                                                btnicon='Users'
                                                title='User Management'
                                                className='linkBtn'
                                            />
                                        </Nav.Link>
                                        <span className='notificationBorder'></span>
                                        <Nav.Link eventKey="">
                                            <CustomIconButton
                                                btnicon={props?.isnotifyunread ? 'BellOnly' : 'BellOnly notification'}
                                                title='Notifications'
                                                className='linkBtn'
                                                onClick={() => {
                                                    setShowNotification(true);
                                                }}
                                            />
                                        </Nav.Link>
                                    </div>
                                    <div className='profileTab'>
                                        <div className='content'>
                                            <Dropdown align="end" id="dropdown-menu-align-end">
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    {userData?.sProfileImage
                                                        ? <div className='sidebarProfile'>
                                                            <img src={process.env.REACT_APP_AWS_DOC_BASE_URL + userData?.sProfileImage} alt='profile' />
                                                        </div>
                                                        : <div className='sidebarProfile'>
                                                            <img src={`${process.env.REACT_APP_AWS_BASE_URL}Static-images/DP.svg`} alt='profile' />
                                                        </div>}
                                                    <span>{userData?.sUserName}</span>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="" onClick={() => { setShowProfile(true); }}><i className='icon icon-User_01'></i>{strings.UPDATEPROFILE}</Dropdown.Item>
                                                    <Dropdown.Item href="" onClick={() => { history('./changepassword'); }}><i className='icon icon-Lock'></i>{strings.CHANGEPASSWORD}</Dropdown.Item>
                                                    <Dropdown.Item href="" onClick={() => { setShowConfirm(true); }}><i className='icon icon-Log_Out'></i>{strings.LOGOUT}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/* {window.sessionStorage.getItem('isBothRole') && */}
                                            {props?.userInfo?.bIsBothRole &&
                                            <>
                                                <p>{strings.SWITCHTO}</p>
                                                <CustomButton
                                                    title='User'
                                                    onClick={() => {
                                                        updatingUserType(userData);
                                                    }}
                                                />
                                            </>
                                            }
                                            {/* {JSON.stringify(window.localStorage.getItem('isBothRole')) } */}
                                        </div>
                                    </div>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                        <div className='profiledropdown' style={{
                            display: (pathname === '/admin/documentmanagement/adddocument' || pathname === '/admin/documentmanagement/editdocument' || pathname === '/admin/archiverequests/editdocument' ||
                                pathname === '/admin/documentmanagement/viewdocument' || pathname === '/admin/archiverequests/viewdocument' || pathname === '/admin/changepassword' || pathname === '/admin/usermanagement/vieweddocuments')
                                ? dimensions.width > 1279 ? 'none' : 'flex'
                                : 'flex'
                        }}>

                            {/* PAGE TITLE---------> */}
                            {pathname === '/admin/documentmanagement' && <p className='pageTitle'>{strings.DOCUMENTS}</p>}
                            {pathname === '/admin/documentmanagement/adddocument' && <p className='pageTitle'style={{ display: dimensions.width > 1279 ? 'none' : 'block' }}>{strings.UPLOAD_DOCUMENT}</p>}
                            {pathname === '/admin/documentmanagement/editdocument' && <p className='pageTitle'style={{ display: dimensions.width > 1279 ? 'none' : 'block' }}>{strings.EDITDOCUMENT}</p>}
                            {pathname === '/admin/documentmanagement/viewdocument' && <p className='pageTitle'style={{ display: dimensions.width > 1279 ? 'none' : 'block' }}>{strings.VIEW_DOCUMENT}</p>}
                            {pathname === '/admin/archiverequests/viewdocument' && <p className='pageTitle'style={{ display: dimensions.width > 1279 ? 'none' : 'block' }}>{strings.VIEW_DOCUMENT}</p>}
                            {pathname === '/admin/usermanagement/vieweddocuments' && <p className='pageTitle'style={{ display: dimensions.width > 1279 ? 'none' : 'block' }}>{strings.USER_MANAGEMENT}</p>}
                            {pathname === '/admin/archiverequests' && <p className='pageTitle'>{strings.ARCHIVED_REQUESTS}</p>}
                            {pathname === '/admin/usermanagement' && <p className='pageTitle'>{strings.USER_MANAGEMENT}</p>}
                            {/* <------------------- */}

                            {(pathname !== '/admin/documentmanagement/adddocument' && pathname !== '/admin/documentmanagement/editdocument' &&
                                pathname !== '/admin/viewdocument' && pathname !== '/admin/usermanagement' &&
                                pathname !== '/admin/archiverequests' && pathname !== '/admin/vieweddocuments' && pathname !== '/admin/changepassword' && pathname !== '/admin/usermanagement/vieweddocuments') &&
                                <CustomIconButton
                                    btnicon='Cloud_Upload'
                                    className='cloudUploadBtn'
                                    title={strings.UPLOAD_DOCUMENT}
                                    onClick={() => {
                                        history(userType ? './documentmanagement/adddocument' : './documentmanagement/adddocument');
                                    }}
                                />}
                        </div>
                    </Navbar>
                </div>
            </div>
            <ModalPopup
                showModal={showProfile}
                className='candidateProfile'
                animation={true}
                onHide={() => {
                }}
            >
                <div className='modalInner'>
                    <div className='modalHeader'>
                        <CustomIconButton
                            btnicon='Close_MD'
                            className='modalClosebtn'
                            type='button'
                            onClick={() => {
                                setShowProfile(false);
                                setProfImg(false);
                            }}
                        />
                    </div>
                    <div className='modalBody'>
                        <HookForm
                            init={(form) => {
                                setUserProfileForm(form);
                            }}
                            onSubmit={(e) => updateAdmin(e)}>
                            {(formMethod) => {
                                return (
                                    <>
                                        <div className='formMain'>
                                            <div className='CandidateImageMain'>
                                                <Controller
                                                    defaultValue=""
                                                    name="image"
                                                    control={formMethod.control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <FileUpload
                                                            onDrop={(acceptedFiles) => {
                                                                onChange(acceptedFiles);
                                                                onPhotoChange(acceptedFiles);
                                                            }}
                                                            accept="image/jpeg,image/jpg,image/png"
                                                        >
                                                            <div className='uploadMain'>
                                                                <div className='uploadBox'>
                                                                    {profImg || userData?.sProfileImage
                                                                        ? (
                                                                            <>
                                                                                <div className='uploaded'>
                                                                                    <img src={profImg || (process.env.REACT_APP_AWS_DOC_BASE_URL + userData?.sProfileImage)} alt='userProfile' />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        : (
                                                                            <>
                                                                                <div className='imageBoxEmpty'>
                                                                                    <div className='hoverUpload'>{strings.UPLOAD}</div>
                                                                                    <img src={`${process.env.REACT_APP_AWS_BASE_URL}Static-images/DP.svg`} alt='userProfile' />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <p className='txtUpload'>{strings.UPLOAD}</p>
                                                        </FileUpload>
                                                    )}
                                                />
                                                <h5>{userData?.sEmail}</h5>
                                            </div>
                                            <TextField
                                                formMethod={formMethod}
                                                name={Profile.Username.name}
                                                rules={Profile.Username.validate}
                                                errors={formMethod?.formState?.errors}
                                                autoFocus={true}
                                                type="text"
                                                placeholder={strings.ENTER_YOUR_FULL_NAME}
                                                iconClass="User_01"
                                                defaultValue={userData?.sUserName}
                                            />
                                            <CustomButton
                                                type="submit"
                                                title={strings.UPDATE}
                                                className='updateBtn'
                                                disabled={!(formMethod?.formState.isValid && ((formMethod.watch(Profile.Username.name) !== userData?.sUserName) || profImg))}
                                                loading={busy}
                                                onClick={() => {
                                                }}
                                            />
                                            <CustomButton
                                                type="button"
                                                title={strings.CANCEL}
                                                className='cancelBtn'
                                                onClick={() => {
                                                    setProfImg(false);
                                                    setShowProfile(false);
                                                }}
                                            />
                                        </div>
                                    </>
                                );
                            }}
                        </HookForm>
                    </div>
                </div>
            </ModalPopup>
            <ConfirmModal
                showModal={showConfirm}
                iconName=''
                title={strings.LOGOUTQUESTION}
                subTitle={strings.SURE_WANT_TO_LOGOUT}
                leftBtnTitle='Cancel'
                rightBtnTitle='Confirm'
                onPressLeft={() => {
                    setShowConfirm(false);
                }}
                onPressRight={() => {
                    setShowConfirm(false);
                    setOnPressConfirm();
                }}
                leftBtnClass=''
                rightBtnClass=''
                className='logOutPopup'
            />
            <ModalPopup
                showModal={showNotification}
                className='notificationModal'
                animation={true}
                onHide={() => {
                }}
            >
                <div className='modalInner'>
                    <div className='modalHeader'>
                        <h2>{strings.NOTIFICATIONS}</h2>
                        <div className='modalHeader-btn'>
                            <CustomButton
                                className='mark-read-all'
                                type='button'
                                disabled = {showNotificationButton || !checkAllNotification}

                                onClick={() => {
                                    markAllReadNotification();
                                }}
                                title="Mark all as read"
                            />
                            <CustomIconButton
                                btnicon='Close_MD'
                                className='modalClosebtn'
                                type='button'
                                onClick={() => {
                                    setShowNotification(false);
                                }}
                            />
                        </div>
                    </div>
                    <div className='modalBody'>
                        <div className='main' onScroll={(e) => {
                            const bottom =
                                e.target.scrollHeight - 1 - e.target.scrollTop ===
                                e.target.clientHeight;
                            if (bottom) {
                                setNotifyPage(notifyPage + 1);
                                notifyNotification(notifyPage + 1);
                            }
                        }}>
                            {props?.notificationInfo && props?.notificationInfo?.length > 0
                                ? props?.notificationInfo?.map((value, index) => {
                                    return value?._id
                                        ? gettingDay(value?._id) === 0
                                            ? (<>
                                                <h1>{strings.TODAY}</h1>
                                                {notificationPopUpData(value)}
                                            </>
                                            )
                                            : gettingDay(value?._id) === 1
                                                ? (<>
                                                    <h1>{strings.YESTERDAY}</h1>
                                                    {notificationPopUpData(value)}
                                                </>
                                                )
                                                : (<>
                                                    <h1>{moment(value?._id, 'MM-DD-YYYY').format('MMM DD YYYY')}</h1>
                                                    {notificationPopUpData(value)}
                                                </>
                                                )
                                        : (
                                            null
                                        );
                                })
                                : <h1>No Notifications Yet</h1>}
                            {/* <h1>{strings.TODAY}</h1>
                            {notificationData?.map((value, index) => {
                                return (
                                    <div className='notificationCard' key={index}>
                                        <p className='text'>{value.title}</p>
                                        <div className='detail'>
                                            <div className='left'>
                                                <div className='documentType'>
                                                    <img src={value.src} alt='thumbnail' />
                                                </div>
                                                <div className='textDetail'>
                                                    <div className='ducumentName'>
                                                        <p className='text'>{value.documentName}</p>
                                                        <span>{value.version}</span>
                                                    </div>
                                                    <p className='subText'>{value.autherName}</p>
                                                </div>
                                            </div>
                                            <div className='right'>
                                                <p>{value.time}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <h1>{strings.TOMMORROW}</h1>
                            {notificationData?.map((value, index) => {
                                return (
                                    <div className='notificationCard' key={index}>
                                        <p className='text'>{value.title}</p>
                                        <div className='detail'>
                                            <div className='left'>
                                                <div className='documentType'>
                                                    <img src={value.src} alt='thumbnail' />
                                                </div>
                                                <div className='textDetail'>
                                                    <div className='ducumentName'>
                                                        <p className='text'>{value.documentName}</p>
                                                        <span>{value.version}</span>
                                                    </div>
                                                    <p className='subText'>{value.autherName}</p>
                                                </div>
                                            </div>
                                            <div className='right'>
                                                <p>{value.time}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })} */}
                        </div>

                    </div>
                </div>
            </ModalPopup>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        showLoaderLoading: state?.mainLoaderReducer?.showLoader,
        notificationInfo: state?.authReducer?.notification,
        isnotifyunread: state?.authReducer?.isNotificationUnread,
        userInfo: state?.authReducer?.userinfo
    };
};
const mapDispatchToProps = {
    showToast,
    showLoader,
    hideLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(Header));
